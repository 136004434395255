
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Field } from "vee-validate";
import PolicyInformation from "@/components/migration/packages/motor-insurance/edit/PolicyInformation.vue";
import VehicleInformation from "@/components/migration/packages/motor-insurance/edit/VehicleInformation.vue";
import variables from "@/router/api";

export default defineComponent({
  name: "admin-policy-migration-edit-motor-policy-index",
  components: { Field, PolicyInformation, VehicleInformation },
  data() {
    return {
      displayPolicyInformation: false,
      displayVehicleInformation: false,
      profileCreation: variables.SUPERADMIN_CLIENT_ROUTE,
      redirect: "admin-policy-migration-list",
    };
  },
  setup() {
    const store = useStore();
    const details = computed(() => store.state.DynamicDetails.details);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit", [
        "Policy Migration",
        "Motor Insurance",
      ]);
    });

    return { details };
  },
  created() {
    //Set page title
    document.title =
      "Administrator: Edit Motor Insurance Policy | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    editPolicyInformation() {
      this.displayVehicleInformation = false;
      this.displayPolicyInformation = true;
    },
    editVehicleInformation() {
      this.displayPolicyInformation = false;
      this.displayVehicleInformation = true;
    },
  },
});
