
import { defineComponent, ref, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import VehicleMakeAndModel from "@/components/reusable/VehicleMakeAndModel.vue";
import VehicleRegAndChassisNumber from "@/components/reusable/VehicleRegAndChassisNumber.vue";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import VehicleColorChart from "@/components/modals/VehicleColorChart.vue";
import VehicleBodyTypesModal from "@/components/modals/VehicleBodyTypes.vue";
import VehicleColors from "@/views/reusable/buy-insurance/motor-insurance/vehicle-colours.json";
import VehicleBodyTypes from "@/views/reusable/buy-insurance/motor-insurance/vehicle-body-types.json";
import VehicleUsage from "@/components/reusable/VehicleUsage.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "motor-insurance-migration-vehicle-component",
  components: {
    Form,
    Field,
    ErrorMessage,
    VehicleMakeAndModel,
    VehicleRegAndChassisNumber,
    ImagesUploader,
    VehicleColorChart,
    VehicleBodyTypesModal,
    VehicleUsage,
    GoBackButton,
  },
  props: {
    details: Object,
    covers: Array,
    redirect: String,
    policyId: String,
  },
  data() {
    return {
      vehicleColors: VehicleColors,
      vehicleBodyTypes: VehicleBodyTypes,
    };
  },
  setup(props) {
    const { capitalizeFirstLetter } = ResusableFunctions();
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();
    const payload = ref({
      vehicleOwner: props?.details?.owner,
      vehicleValue: props?.details?.value,
      vehicleMake: props?.details?.vehicleMake?.publicId,
      vehicleModel: props?.details?.vehicleModel?.publicId,
      vehicleRegistrationNumber: props?.details?.registrationNumber,
      chassisNumber: props?.details?.chassisNumber,
      engineNumber: props?.details?.engineNumber,
      engineCapacity: props?.details?.engineCapacity,
      yearManufactured: props?.details?.yearManufactured,
      vehicleColor: capitalizeFirstLetter(props?.details?.colour),
      bodyType: capitalizeFirstLetter(props?.details?.bodyType),
      vehicleImages: "",
      vehiclePremium: props?.details?.premium,
      rate: props?.details?.rate,
      vehicleLicense: "",
      package: props?.details?.package?.publicId,
    });

    const vehicleOwnerNameUpdate = (event) => {
      const realVehicleOwnerName = document.getElementById(
        "real-vehicle-owner"
      ) as HTMLInputElement;

      const value = event.target.value;
      realVehicleOwnerName.value = value;
      payload.value.vehicleOwner = value;
    };

    const policyImageSelected = (event, name: string) => {
      payload.value[name] = event.target.files[0];
    };

    const vehicleLicenseSelected = (event) => {
      payload.value.vehicleLicense = event.target.files[0];
    };

    const vehicleImagesSelected = (event) => {
      payload.value.vehicleImages = event.target.files;
    };

    const validationSchema = Yup.object().shape({
      vehicles: Yup.array()
        .of(
          Yup.object().shape({
            owner: Yup.string()
              .required()
              // .min(6)
              .label("Vehicle owner name"),
            vehicleValue: Yup.string()
              .when("package", {
                is: (value) => value != JwtService.getThirdPartyPM(),
                then: Yup.string()
                  // .min(6)
                  .required(),
              })
              .label("Vehicle value"),
            package: Yup.mixed()
              .required()
              .label("Cover"),
            vehicleMake: Yup.mixed()
              .required()
              .label("Vehicle brand"),
            vehicleModel: Yup.string()
              .required()
              .label("Vehicle model"),
            registrationNumber: Yup.string()
              .required()
              // .min(8)
              .label("Vehicle registration number"),
            chassisNumber: Yup.string()
              .required()
              .min(1)
              .label("Chassis number"),
            engineNumber: Yup.string()
              .required()
              .min(1)
              .label("Engine number"),
            bodyType: Yup.string()
              .required()
              .label("Body type"),
            vehicleColor: Yup.string()
              .required()
              .label("Vehicle colour"),
            vehicleLicense: Yup.mixed()
              .required()
              .label("Vehicle license"),
          })
        )
        .strict(),
    });

    const updateVehicleInformation = (values, { resetForm }) => {
      console.clear();

      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            // const formData = new FormData();
            const formData = new FormData(document.forms[0]);
            formData.append("folioItem", props?.details?.publicId);
            formData.append("businessClass", "MOT");
            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(
              `${variables.POLICIES_ROUTE}/${props?.policyId}/folio-item`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                router.push({
                  name: props?.redirect,
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    const numberFormatter = () => {
      const ids = ["vehicle-value", "vehicle-premium-field"];
      ids.forEach((id: string) => {
        variables.numberFormatter(id);
      });
    };

    onMounted(() => {
      numberFormatter();
    });

    return {
      updateVehicleInformation,
      policyImageSelected,
      vehicleOwnerNameUpdate,
      validationSchema,
      submitButton1,
      payload,
      vehicleLicenseSelected,
      vehicleImagesSelected,
    };
  },
});
