
import { defineComponent, ref, onMounted, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import EditVehicleDetails from "@/components/migration/packages/motor-insurance/edit/Vehicle.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "motor-insurance-migration-vehicle-information-component",
  components: { EditVehicleDetails },
  props: {
    details: Object,
    redirect: String,
  },
  setup(props) {
    const { generateUUID } = ResusableFunctions();
    const payload = ref({
      vehicles: props?.details?.items ?? [],
      details: {},
      display: false,
      key: "",
      covers: [],
    });

    const addCommaToNumberString = (amount: string | number) => {
      return variables.addCommaToNumberString(amount);
    };

    const editVehicleDetails = (details: object) => {
      payload.value.details = details;
      payload.value.display = true;
      payload.value.key = generateUUID();
    };

    const getCovers = (businessType: string) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.INSURANCE_PACKAGES_ROUTE}/${businessType}`)
          .then(({ data }) => {
            payload.value.covers = data.data;
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    watch(
      () => props?.details?.policySummary?.businessTypeShortName,
      (shortName: string) => {
        getCovers(shortName);
      }
    );

    onMounted(() => {
      getCovers(props?.details?.policySummary?.businessTypeShortName);
    });

    return {
      addCommaToNumberString,
      editVehicleDetails,
      payload,
    };
  },
});
